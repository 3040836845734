import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { FiArrowRightCircle } from "react-icons/fi";
// import Image from "@components/common/CloudinaryImage";
// import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>El Llibre</Title>

                <Text>
                    L’Èxit no és fruit de la casualitat sinó una conseqüència de l’esforç. Per tant
                    si tens aquest llibre a les teves mans no és fruit d’una decisió aleatòria sinó
                    que neix d’un desig molt concret d’aconseguir un SOMNI. El somni de formar part
                    de les Forces i Cossos de Seguretat:
                    <br />
                    <br />
                    * Les forces i cossos de seguretat de l'Estat dependents del Govern central.
                    <br />
                    <br />
                    * Els Cossos de Policia dependents de les Comunitats Autònomes, fent especial
                    atenció en el cos de MOSSOS D’ESQUADRA.
                    <br />
                    <br />
                    * Els Cossos de Policia dependents de les Corporacions Locals, fent especial
                    atenció en el cos de la GUÀRDIA URBANA DE BARCELONA.
                    <br />
                    <br />
                    Aquest llibre és per tant, una eina imprescindible per aconseguir dominar la
                    prova de psicotècnics aptitudinals.
                    <br />
                    <br />
                    Per tal de facilitar l’aprenentatge i la integració dels coneixements
                    necessaris, s’ha estructurat tot el llibre en 5 raonaments ( espacial,
                    abstracte, verbal, numèric i visual ) més las possibilitat de posar-te a prova
                    amb 4 simulacres basats en les proves oficials de Mossos d’Esqudra i Guàrdia
                    Urbana de Barcelona.
                    <br />
                    <br />
                    Esperem doncs que aquest llibre et sigui de molta utilitat i aconsegueixis el
                    teu somni de formar part d’aquesta gran familia, de la qual ens sentim tant
                    orgullosos.
                    <br />
                    <br />
                    Fins aviat company!
                    <br />
                    <br />
                    <br />
                    {/*<strong>
                        Els enviaments es faran en estricte ordre de compra i a
                        partir del dia 7 de febrer, només arribar des de
                        l'impremta.
                    </strong>*/}
                </Text>

                {/** Preview del libro */}
                <div className="container lg:px-0 py-20">
                    <Title>Preview del libro</Title>
                    <Text className="text-justify">
                        A continuació us ensenyem un extracte variat del llibre.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/205/demo_psicos.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                    <div className="mt-10">
                        Si no veus correctament el pdf pots fer clic en aquest{" "}
                        <a
                            className="border-b"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={require("@static/documents/products/205/demo_psicos.pdf")}
                        >
                            enllaç
                        </a>
                        .
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
